import { createStore } from 'vuex'

export default createStore({
    state: {
        basePath: location.protocol + '//' + location.hostname,
        listAddress: []
    },

    mutations: {
        changeListAddress (state, payload) {
            state.listAddress = payload
        }
    },

    actions: {
        async PersonAddAddress (context, payload) {
            if ((process.env.NODE_ENV === 'development')) {
                console.log(payload)
            } else {
                const fields = {
                    userId: payload.setting.userId,
                    fields: payload.fields
                }

                const formData = new FormData()
                formData.append('fields', JSON.stringify(fields))

                const response = await fetch(`/api/v1/office/personal/address/add/`, {
                    method: 'POST',
                    body: formData
                })

                const result = await response.json()
                await context.commit('changeListAddress', result)
                console.log(result)
            }
        },

        async PersonListAddress (context, payload) {
            if ((process.env.NODE_ENV === 'development')) {
                console.log(payload)
            } else {
                const fields = {
                    userId: payload.userId,
                    adrKey: payload.adrKey,
                    type: payload.type
                }

                const formData = new FormData()
                formData.append('fields', JSON.stringify(fields))

                const response = await fetch(`/api/v1/office/personal/address/list/`, {
                    method: 'POST',
                    body: formData
                })

                const result = await response.json()
                await context.commit('changeListAddress', result)
                console.log(result)
            }
        }
    },
    modules: {

    },
    getters: {
        basePath: s => s.basePath,
        listAddress: s => s.listAddress
    }
})
