/*global $*/
/*eslint no-undef: "error"*/

export default class CartPopup {
    classButton = 'cart--button'
    classBlock = 'cart--block'
    classBlockOpen = 'layout__order-open'
    classIconActive = 'icon-header-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classIconActive)
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
