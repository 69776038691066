/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import './assets/js/css/ImportCss'
import Cookies from 'js-cookie'
import tippy from 'tippy.js'
import { Fancybox } from '@fancyapps/ui'
import Inputmask from 'inputmask'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Faq from '@/assets/js/Faq/Faq'
import ButtonAbout from '@/assets/js/Button/ButtonAbout/ButtonAbout'
import ButtonTop from '@/assets/js/Button/ButtonTop/ButtonTop'
import CartPopup from '@/assets/js/Cart/CartPopup/CartPopup'
import ProductCount from '@/assets/js/ProductCount/ProductCount'
import ProductOption from '@/assets/js/Product/Option/ProductOption'
import ProductAdd from '@/assets/js/Product/Add/ProductAdd'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import InputPayment from '@/assets/js/Input/InputPayment/InputPayment'
import InputAddress from '@/assets/js/Input/Address/InputAddress'
import InputAddressSelect from '@/assets/js/Input/Address/Select/InputAddressSelect'
import InputAuth from '@/assets/js/Input/Auth/InputAuth'
import Auth from '@/assets/js/Auth/Auth'
import OfficeEmail from '@/assets/js/Office/Email/OfficeEmail'
import Tab from '@/assets/js/Tab/Tab'
import Map from '@/assets/js/Map/Map'
import Important from '@/assets/js/Important/Important'
import form from '@/store/form'
import office from '@/store/office'
import psb from '@/store/psb'
import AppFormQuestion from '@/components/Form/Question/AppFormQuestion'
import AppFormReview from '@/components/Form/Review/AppFormReview.vue'
import AppFormPopupBooking from '@/components/Form/Popup/AppFormPopupBooking.vue'
import AppFormPSB from '@/components/Form/PSB/AppFormPSB.vue'
import AppOfficeAddress from '@/components/Office/Address/AppOfficeAddress.vue'

const $ = window.$ = window.jQuery = require('jquery')

// Подключаем глобально Cookie
window.Cookies = Cookies

document.addEventListener('DOMContentLoaded', () => {
    Inputmask('7 (999) 999-9999').mask('.input-text--phone')

    tippy('.tippy--status', {
        theme: 'material',
        arrow: true,
        placement: 'right',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    tippy('.button-icon-content-bottom', {
        theme: 'light',
        arrow: true,
        maxWidth: 1000,
        placement: 'bottom-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    Fancybox.bind('[data-subscribe]')

    new SwiperSlider()
    new Faq()
    new ButtonAbout()
    new ButtonTop()
    new CartPopup()
    new ProductCount()
    new ProductOption()
    new ProductAdd()
    new HeaderMobile()
    new InputPayment()
    new InputAddress()
    new InputAddressSelect()
    new InputAuth()
    new Auth()
    new OfficeEmail()
    new Tab()
    new Map()
    new Important()

    // Form
    if(document.getElementById('form--question')!= null) {
        createApp({})
            .component('form-question', AppFormQuestion)
            .use(form)
            .use(Maska)
            .mount('#form--question')
    }

    if(document.getElementById('form--review')!= null) {
        createApp({})
            .component('form-review', AppFormReview)
            .use(form)
            .use(Maska)
            .mount('#form--review')
    }

    if(document.getElementById('form--booking')!= null) {
        createApp({})
            .component('form-booking', AppFormPopupBooking)
            .use(form)
            .use(Maska)
            .mount('#form--booking')
    }

    if(document.getElementById('form--psb')!= null) {
        createApp({})
            .component('form-psb', AppFormPSB)
            .use(psb)
            .use(Maska)
            .mount('#form--psb')
    }
    // Form #END

    // Office
    if(document.getElementById('office--address')!= null) {
        createApp({})
            .component('office-address', AppOfficeAddress)
            .use(office)
            .mount('#office--address')
    }
    // Office #END
})
