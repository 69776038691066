/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderMobile {
    classButton = 'header--button'
    classBlock = 'header--block'
    classBlockOpen = 'header-mobile-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
