<template lang="pug">
.address-block
  .address-block__items(
    v-if="listAddress.length"
    :class="{'address-block__items-open': isAddAddress}"
  )
    .address-item(
      v-for="(item, key) of listAddress"
      :key="key"
    )
      .address-item__address
        .address-item__street(v-html="item['street']" )
        .address-item__text(v-html="item['text']")

      .address-item__select
        .address-item__item
          .address-select(
            :class="{'address-select-select': parseInt(item['select']) === 1}"
            @click="changeActiveAddress(key)"
          )
            svg.address-select__icon.svg.address-select__icon-fill
              use(xlink:href="#icon-arrow2")
            .address-select__text Выбранный адрес

        .address-item__item
          .address-select.address-select-remove(
            @click="deleteActiveAddress(key)"
          )
            svg.address-select__icon.svg.address-select__icon-stroke
              use(xlink:href="#icon-close")
            .address-select__text Удалить адрес

  .address-block__info(v-else v-html="textInfo")

  .address-block__form(
    :class="{'address-block__form-open': isAddAddress}"
  )
    .address-form
      h3 Добавить новый адрес

      .address-form__street
        .input-address.input-address-white
          input.input-address__input(type="text" placeholder="Ваша улица *" v-model="street")
          svg.input-address__icon
            use(xlink:href="#icon-contact-address2")

      .address-form__fields
        .address-form__field
          label.input-text.input-text-center
            span.input-text__title Дом *
            input.input-text__input(type="text" placeholder="Дом *" v-model="building")

        .address-form__field
          label.input-text.input-text-center
            span.input-text__title Парадная
            input.input-text__input(type="text" placeholder="Парадная" v-model="entrance")

        .address-form__field
          label.input-text.input-text-center
            span.input-text__title Этаж
            input.input-text__input(type="text" placeholder="Этаж" v-model="floor")

        .address-form__field
          label.input-text.input-text-center
            span.input-text__title Квартира
            input.input-text__input(type="text" placeholder="Квартира" v-model="room")

      .address-form__buttons
        .address-form__button
          .button(
            @click="getAddAddress()"
            :class="{'button-disable': v$.$invalid}"
          )
            .button__name(v-html="'Добавить'")

        .address-form__button
          .address-form__cancel(
            @click="addAddress()"
            v-html="'Отмена'"
          )

  .address-block__add(
    :class="{'address-block__add-open': isAddAddress}"
  )
    .address-block__add-text(
      @click="addAddress()"
      v-html="'+ Добавить адрес'"
    )

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AppOfficeAddress',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    'userId',
    'textInfo'
  ],

  data () {
    return {
      isAddAddress: false,
      street: '',
      building: '',
      entrance: '',
      floor: '',
      room: ''
    }
  },

  computed: {
    ...mapGetters([
        'basePath',
        'listAddress'
    ])
  },

  methods: {
    ...mapActions([
        'PersonAddAddress',
        'PersonListAddress'
    ]),

    addAddress () {
      this.isAddAddress = !this.isAddAddress
    },

    changeActiveAddress (adrKey) {
        this.PersonListAddress({userId: this.userId, adrKey, type: 'active'})
    },

    deleteActiveAddress (adrKey) {
      this.PersonListAddress({userId: this.userId, adrKey, type: 'delete'})
    },

    getAddAddress () {
      if (!this.v$.$invalid && this.isAddAddress) {
        this.isAddAddress = !this.isAddAddress

        this.PersonAddAddress({
          setting: {
            userId: this.userId
          },
          fields: [
            {
              name: 'street',
              title: 'Улица',
              value: this.street
            },
            {
              name: 'building',
              title: 'Дом',
              value: this.building
            },
            {
              name: 'entrance',
              title: 'Парадная',
              value: this.entrance
            },
            {
              name: 'floor',
              title: 'Этаж',
              value: this.floor
            },
            {
              name: 'room',
              title: 'Квартира',
              value: this.room
            }
          ]
        })

        this.street = this.building = this.entrance = this.floor = this.room = ''
      }
    }
  },

  validations () {
    return {
      street: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(200)
      },
      building: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50)
      }
    }
  },

  mounted () {
    this.PersonListAddress({userId: this.userId, adrKey: 0, type: 'list'})
  }
}
</script>
