<template lang="pug">
.form-psb
  .form-psb__fields
    .form-psb__field
      .input-text.input-text-gray
        input.input-text__input(
          type="text"
          v-model="fieldName"
          :placeholder="formFieldNamePlaceholder"
        )
    .form-psb__field
      .input-text.input-text-gray(:class="{'input-text-error': fieldError && v$.fieldPrice.$invalid}")
        input.input-text__input(
          type="number"
          v-model.number="fieldPrice"
          :placeholder="formFieldPricePlaceholder"
        )

    .form-psb__field
      .input-text.input-text-gray(:class="{'input-text-error': fieldError && v$.fieldPhone.$invalid}")
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ###-####', '+7 (###) ###-####']"
        )

    .form-psb__field
      .input-text.input-text-gray(:class="{'input-text-error': fieldError && v$.fieldEmail.$invalid}")
        input.input-text__input(
          type="email"
          v-model="fieldEmail"
          :placeholder="formFieldEmailPlaceholder"
        )

    .form-psb__field
      .input-payment(:class="{'input-payment-open': fieldSelectOpen, 'input-payment-error': fieldError && v$.fieldSelect.$invalid}")
        .input-payment__button(@click="fieldSelectOpen = !fieldSelectOpen")
          .input-payment__name(v-html="fieldSelectDefault")

          svg.input-payment__arrow
            use(xlink:href="#icon-arrow")

        .input-payment__list
          .input-payment__item(v-for="selectItem in fieldSelectList"  @click="setFieldSelect(selectItem)")
            .input-payment__item-name(v-html="selectItem")

    .form-psb__field
      label.input-textarea.input-textarea-gray.input-textarea-height-10
        textarea.input-textarea__input(
          v-model="fieldMessage"
         :placeholder="formFieldMessagePlaceholder"
        )

  .form-psb__consent
    label.input-checkbox(:class="{'input-checkbox-error': fieldError && v$.consent.$invalid}")
      input.input-checkbox__input(
        type="checkbox"
        value="consent"
        v-model="consent"
      )

      span.input-checkbox__blocks
        span.input-checkbox__button
        span.input-checkbox__label(v-html="formConsent")

  .form-psb__button
    .button.button-inline.button-orange(@click="getSend()")
      .button__name(v-html="formButtonName")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPSB',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPricePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldEmailPlaceholder",
    "formFieldMessage",
    "formFieldMessagePlaceholder",
    "formFieldEventDefault",
    "formFieldEventList",
    "formFieldEventCurrent",

    "formButtonName",

    "formConsent",
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldEmail: '',
      fieldPrice: '',
      fieldMessage: '',
      fieldSelectDefault: (this.formFieldEventCurrent)? this.formFieldEventCurrent: this.formFieldEventDefault,
      fieldSelect: this.formFieldEventCurrent,
      fieldSelectList: '',
      fieldSelectOpen: false,
      fieldError: false,
      consent: ['consent']
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    setFieldSelect (value) {
      this.fieldSelectDefault = value
      this.fieldSelect = value
      this.fieldSelectOpen = false
    },

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm
        this.Send({
          setting: {
            price: this.fieldPrice,
            eventName: this.fieldSelect,
            email: this.fieldEmail
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldMessage,
              value: this.fieldMessage
            },
          ]
        })

        this.fieldName = this.fieldPrice = this.fieldEmail = this.fieldPhone = this.fieldMessage = ''
      }
    }
  },

  validations () {
    return {
      fieldPrice: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(7)
      },
      fieldEmail: {
        required,
        email
      },
      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },
      fieldSelect: {
        required
      },
      consent: {
        required
      }
    }
  },

  created () {
    this.fieldSelectList = this.formFieldEventList.split('||')
  }
}
</script>
