/*global $*/
/*eslint no-undef: "error"*/

export default class InputPayment {
    classBlock = 'input-payment--block'
    classBlockOpen = 'input-payment-open'
    classButton = 'input-payment--button'
    classItem = 'input-payment--item'
    className = 'input-payment--name'
    classInput = 'input-payment--input'

    classFieldAddress = 'order__addresses'
    classFieldAddressHidden = 'order__addresses-hidden'

    // classInputAddress = 'input-payment--address'
    // classInputAddressChange = 'input-address-change--input'

    classInputAddressBlock = 'input-payment-address'
    classInputName = 'input-payment--name'

    classTitleSale = 'input-payment--title-sale'
    classTitleDelivery = 'input-payment--title-delivery'
    classTitleHidden = 'order-total__title-hidden'
    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })

        $(`.${self.classItem}`).click(function () {
            let type = $(this).closest(`.${self.classBlock}`).data('block')
            let name = $(this).data('name')
            let number = $(this).data('number')
            let objBlock = $(this).closest(`.${self.classBlock}`)

            objBlock.removeClass(self.classBlockOpen)
            objBlock.find(`.${self.className}`).text(name)

            if (type === "delivery") {
                if (number === 2) {
                    let defaultText = $(`.${self.classInputAddressBlock}`).find(`.${self.classInputName}`).text()

                    $(`.${self.classFieldAddress}`).find(`input[name='text_address']`).val(defaultText)
                    $(`.${self.classFieldAddress}`).removeClass(self.classFieldAddressHidden)

                    $(`.${self.classTitleDelivery}`).removeClass(self.classTitleHidden)
                    $(`.${self.classTitleSale}`).addClass(self.classTitleHidden)
                } else {
                    // $(`.${self.classFieldAddress}`).find(`input`).val('')
                    $(`.${self.classFieldAddress}`).addClass(self.classFieldAddressHidden)

                    $(`.${self.classTitleDelivery}`).addClass(self.classTitleHidden)
                    $(`.${self.classTitleSale}`).removeClass(self.classTitleHidden)
                }
            }
        })
    }
}
