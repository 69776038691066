/*global $*/
/*eslint no-undef: "error"*/

export default class Auth {
    classItem  = 'auth--item'
    classPrev  = 'auth--prev'

    classItemCode  = 'auth__block-code'

    constructor () {
        let self = this

        $(`.${self.classPrev}`).click(function () {
            $(this).closest(`.${self.classItem}`).removeClass(self.classItemCode)
        })
    }
}
