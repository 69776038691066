/*global $*/
/*eslint no-undef: "error"*/

export default class InputAuth {
    classItem = 'input--auth-item'
    classInput = 'input--auth-input'
    classClose = 'input--auth-close'

    classItemActive = 'input-auth-active'

    constructor () {
        let self = this

        $(`.${self.classInput}`).keyup(function () {
            let numberPattern = /\d+/g
            let curValue = $(this).val()

            if (curValue.length > 0) {
                curValue = $(this).val().match(numberPattern).join('')
            }

            if (curValue.length > 1) {
                $(this).closest(`.${self.classItem}`).addClass(self.classItemActive)
            } else {
                $(this).closest(`.${self.classItem}`).removeClass(self.classItemActive)
            }
        })

        $(`.${self.classClose}`).click(function () {
            $(this).closest(`.${self.classItem}`).find(`.${self.classInput}`).val('')
            $(this).closest(`.${self.classItem}`).removeClass(self.classItemActive)
        })
    }
}
