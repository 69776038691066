/*global $*/
/*eslint no-undef: "error"*/

export default class OfficeEmail {
    classInput = 'office-email--input'
    classCopy = 'office-email--copy'

    constructor () {
        let self = this

        $(`.${self.classInput}`).keyup(function () {
            let curValue = $(this).val()

            $(`.${self.classCopy}`).val(curValue)
        })
    }
}
