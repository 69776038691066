/*global $*/
/*eslint no-undef: "error"*/

export default class ProductOption {
    classItem = 'product-option--item'
    classButton = 'product-option--button'
    classSelect = 'product-option--select'

    classItemSuccess = 'product-option-success'
    classButtonActive = 'product-option__block-active'

    constructor() {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let curID = $(this).data('id')

            if (!$(this).closest(`.${self.classItem}`).hasClass(self.classItemSuccess)) {
                $(this).closest(`.${self.classItem}`).addClass(self.classItemSuccess)
            }

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classSelect} option[data-id="${curID}"]`).prop('selected', true)
        })
    }
}
