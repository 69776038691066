import { createStore } from 'vuex'

export default createStore({
    state: {},
    mutations: {},
    actions: {
        async Send (context, payload) {
            if ((process.env.NODE_ENV === 'development')) {
                console.log(payload)
            } else {
                const fields = {
                    price: payload.setting.price,
                    eventName: payload.setting.eventName,
                    email: payload.setting.email,
                    fields: payload.fields
                }

                const formData = new FormData()
                formData.append('fields', JSON.stringify(fields))

                const response = await fetch(`/api/v1/psb/form/`, {
                    method: 'POST',
                    body: formData
                })

                const result = await response.json()

                if (typeof result.REF !== 'undefined') {
                    window.location.href = result.REF
                }
            }
        }
    },
    modules: {
    },
    getters: {}
})
