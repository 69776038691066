/*global $*/
/*eslint no-undef: "error"*/

export default class ProductCount {
    classItem = 'input-count'
    classCount = 'input-count__count'
    classChange = 'input-count--change'

    classButtonMinus = 'input-count__button--minus'
    classButtonPlus = 'input-count__button--plus'

    constructor () {
        let self = this

        $(document).on('click', `.${self.classButtonPlus}`, function () {
            let objItem = $(this).closest(`.${self.classItem}`)
            let count = parseInt($(this).closest(`.${self.classItem}`).find(`.${self.classCount}`).val())
            count += 1

            objItem.find(`.${self.classCount}`).val(count)
            objItem.find(`.${self.classChange}`).click()
        })

        $(document).on('click', `.${self.classButtonMinus}`, function () {
            let objItem = $(this).closest(`.${self.classItem}`)
            let min = parseInt(objItem.data('min'))

            let count = parseInt($(this).closest(`.${self.classItem}`).find(`.${self.classCount}`).val())

            if (count > min) {
                count -= 1

                objItem.find(`.${self.classCount}`).val(count)
                objItem.find(`.${self.classChange}`).click()
            }
        })
    }
}
