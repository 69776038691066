/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonAbout {
    classButton = 'tab--button-about'
    classButtonActive = 'tab-active'

    classBLock = 'about-blocks--block'
    classBlockActive = 'about-blocks__item-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(`.${self.classBLock}`).removeClass(self.classBlockActive)
            $(`.${self.classBLock}[data-block="${block}"]`).addClass(self.classBlockActive)
        })
    }
}
