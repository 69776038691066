/*global $*/
/*eslint no-undef: "error"*/

export default class ProductAdd {
    classItem = 'product-add--item'
    classButton = 'product-add--button'
    classInput = 'product-add--input'

    classButtonActive = 'product-add__block-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let productArr = []
            let nameInput = $(this).closest(`.${self.classItem}`).data('input')

            $(this).toggleClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classButtonActive}`).each((key, item) => {
                let productName = $(item).data('product')

                productArr.push(productName)
            })

            if (productArr.length) {
                $(this).closest(`.${self.classItem}`).find(`.${self.classInput}`).attr('name', nameInput)
                $(this).closest(`.${self.classItem}`).find(`.${self.classInput}`).val(productArr.join('||'))
            } else {
                $(this).closest(`.${self.classItem}`).find(`.${self.classInput}`).attr('name', 'null_' + nameInput)
            }
        })
    }
}
