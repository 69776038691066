/*global $*/
/*eslint no-undef: "error"*/
export default class InputAddress {
    classInput = 'input-address-change--input'
    classHidden = 'input-address-change--hidden'

    classInputAddress = 'input-payment-address'
    classInputName = 'input-payment--name'

    constructor() {
        let self = this

        $(`.${self.classInput} input`).keyup(function () {
            let text = $(this).val()

            $(`.${self.classHidden} input`).val(text)

            if (text.length === 0) {
                let defaultText = $(`.${self.classInputAddress}`).find(`.${self.classInputName}`).text()

                $(`.${self.classHidden} input`).val(defaultText)
            }
        })
    }
}
