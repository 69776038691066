<template lang="pug">
.form-question
  .form-question__block(v-show="!sendForm")
    .form-question__fields
      .form-question__field
        .input-text
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            placeholder="+7 (_ _ _) _ _ _ -_ _ _ _ "
            v-maska="['+7 (###) ###-####', '+7 (###) ###-####']"
          )

      .form-question__field
        .button.button-name-white(
          :class="{'button-disable': v$.$invalid}"
          @click="getSend()"
        )
          .button__name(v-html="formButtonName")

    .form-question__consent
      label.input-checkbox.input-checkbox-white
        input.input-checkbox__input(
          type="checkbox"
          value="consent"
          v-model="consent"
        )

        span.input-checkbox__blocks
          span.input-checkbox__button
          span.input-checkbox__label(v-html="formConsentText")

  .form-question__block(v-show="sendForm")
    .form-question__success
      .success.success-white
        h3.center(v-html="formSuccessTitle")
        p.center(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormQuestion',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",
    "formFieldPhone",
    "formConsentText",
    "formButtonName",
    "formSuccessTitle",
    "formSuccessText",
    "formPageId",
    "formPageTitle"
  ],

  data () {
    return {
      fieldPhone: '',
      files: '',
      consent: ['consent'],
      sendForm: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm
        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject,
            emailType: 'configEmailDo',
            smsType: ''
          },

          fields: [
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: 'Форма отправлена со странице',
              value: this.formPageTitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 5000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },
      consent: {
        required
      }
    }
  }
}
</script>
