/*global $*/
/*global Cookies*/
/*eslint no-undef: "error"*/
export default class Important {
    classItem = 'important--item'
    classButton = 'important--button'

    classShow = 'important-show'

    cookieName = 'important-plushkin'
    cookieValue = 'yes'

    constructor () {
        let self = this

        if (!Cookies.get(self.cookieName)) {
            $(`.${self.classItem}`).addClass(self.classShow)

            $(`.${self.classButton}`).click(function () {
                $(this).closest(`.${self.classItem}`).removeClass(self.classShow)
                Cookies.set(self.cookieName, self.cookieValue, { expires: 1 })
            })
        }
    }
}
