import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSections () {
        return new Swiper('#slider--menu', {
            slidesPerView: 6,
            spaceBetween: 5,
            loop: false,
            navigation: {
                nextEl: '.slider--menu-next',
                prevEl: '.slider--menu-prev',
            },
            pagination: {
                el: '.swiper-pagination--menu',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 2
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 3
                },
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 4
                },
                991: {
                    slidesPerView: 5
                },
                1024: {
                    slidesPerView: 5
                },
                1240: {
                    slidesPerView: 5
                },
                1440: {
                    slidesPerView: 6
                }
            }
        })
    }

    createSlider () {
        return new Swiper('#slider--slider', {
            slidesPerView: 1,
            spaceBetween: 5,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createStocks () {
        return new Swiper('#slider--stocks', {
            slidesPerView: 1,
            spaceBetween: 5,
            navigation: {
                nextEl: '.slider--stocks-next',
                prevEl: '.slider--stocks-prev',
            },
            pagination: {
                el: '.swiper-pagination--stocks',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createTabs () {
        return new Swiper('#slider--tabs', {
            slidesPerView: 5,
            spaceBetween: 5,
            navigation: {
                nextEl: '.slider--tabs-next',
                prevEl: '.slider--tabs-prev',
            },
            pagination: {
                el: '.swiper-pagination--tabs',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 2
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 3
                },
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 4
                },
                991: {
                    slidesPerView: 5
                },
                1024: {
                    slidesPerView: 5
                },
                1240: {
                    slidesPerView: 4
                },
                1440: {
                    slidesPerView: 5
                }
            }
        })
    }

    createReview () {
        return new Swiper('#slider--review', {
            slidesPerView: 2,
            spaceBetween: 15,
            navigation: {
                nextEl: '.slider--review-next',
                prevEl: '.slider--review-prev',
            },
            pagination: {
                el: '.swiper-pagination--review',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                },
                1440: {
                    slidesPerView: 2
                }
            }
        })
    }

    createNews () {
        return new Swiper('#slider--news', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--news-next',
                prevEl: '.slider--news-prev',
            },
            pagination: {
                el: '.swiper-pagination--news',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 3
                },
                1440: {
                    slidesPerView: 3
                }
            }
        })
    }

    createProducts () {
        return new Swiper('#slider--products', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--products-next',
                prevEl: '.slider--products-prev',
            },
            pagination: {
                el: '.swiper-pagination--products',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 3
                },
                1440: {
                    slidesPerView: 3
                }
            }
        })
    }

    createSub () {
        return new Swiper('#slider--sub', {
            slidesPerView: 5,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--sub-next',
                prevEl: '.slider--sub-prev',
            },
            pagination: {
                el: '.swiper-pagination--sub',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 4,
                },
                991: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 5
                },
                1240: {
                    slidesPerView: 5
                },
                1440: {
                    slidesPerView: 5
                }
            }
        })
    }

    createGallery () {
        return new Swiper('#slider--gallery', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 2
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 2
                },
                1440: {
                    slidesPerView: 2
                }
            }
        })
    }

    createGallery2 () {
        return new Swiper('#slider--gallery2', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--gallery2-next',
                prevEl: '.slider--gallery2-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery2',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 2
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                },
                1440: {
                    slidesPerView: 2
                }
            }
        })
    }

    createCarte () {
        return new Swiper('#slider--carte', {
            slidesPerView: 5,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--carte-next',
                prevEl: '.slider--carte-prev',
            },
            pagination: {
                el: '.swiper-pagination--carte',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2
                },
                320: {
                    slidesPerView: 2
                },
                360: {
                    slidesPerView: 2
                },
                375: {
                    slidesPerView: 2
                },
                414: {
                    slidesPerView: 3
                },
                480: {
                    slidesPerView: 3
                },
                514: {
                    slidesPerView: 3,
                },
                640: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 4,
                },
                991: {
                    slidesPerView: 5
                },
                1024: {
                    slidesPerView: 5
                },
                1240: {
                    slidesPerView: 5
                },
                1440: {
                    slidesPerView: 5
                }
            }
        })
    }

    createEvent () {
        return new Swiper('#slider--event', {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--event-next',
                prevEl: '.slider--event-prev',
            },
            pagination: {
                el: '.swiper-pagination--event',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1,
                },
                640: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 2
                },
                1440: {
                    slidesPerView: 2
                }
            }
        })
    }

    createSliderEvent () {
        let swiper = new Swiper("#slider--slider-event-small", {
            loop: false,
            preloadImages: false,
            lazy: true,
            spaceBetween: 22,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 10
                }
            }
        })

        return new Swiper("#slider--slider-event", {
            loop: false,
            preloadImages: false,
            lazy: false,
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-slider-event-next",
                prevEl: ".swiper-slider-event-prev",
            },
            thumbs: {
                swiper: swiper,
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--menu')!= null) {
            this.createSections()
        }

        if(document.getElementById('slider--slider')!= null) {
            this.createSlider()
        }

        if(document.getElementById('slider--stocks')!= null) {
            this.createStocks()
        }

        if(document.getElementById('slider--tabs')!= null) {
            this.createTabs()
        }

        if(document.getElementById('slider--review')!= null) {
            this.createReview()
        }

        if(document.getElementById('slider--news')!= null) {
            this.createNews()
        }

        if(document.getElementById('slider--products')!= null) {
            this.createProducts()
        }

        if(document.getElementById('slider--sub')!= null) {
            this.createSub()
        }

        if(document.getElementById('slider--gallery')!= null) {
            this.createGallery()
        }

        if(document.getElementById('slider--gallery2')!= null) {
            this.createGallery2()
        }

        if(document.getElementById('slider--carte')!= null) {
            this.createCarte()
        }

        if(document.getElementById('slider--event')!= null) {
            this.createEvent()
        }

        if(document.getElementById('slider--slider-event')!= null) {
            this.createSliderEvent()
        }
    }
}
